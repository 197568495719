@font-face {
    font-family: georgia;
    src: url(assets/fonts/georgia.ttf);
}
@font-face {
    font-family: elephant;
    src: url(assets/fonts/elephant.ttf);
}
@font-face {
    font-family: courier;
    src: url(assets/fonts/courier.ttf);
}
@font-face {
    font-family: arial;
    src: url(assets/fonts/arial.ttf);
}
@font-face {
    font-family: helvetica;
    src: url(assets/fonts/helvetica.ttf);
}
#logArea {
    font-size: 14px;
    font-weight: normal;
    resize: none;
    overflow: auto;
    width: 100%;
    height: 100%;
}
#log-container {
    position: absolute;
    left: 20px;
    top: 20px;
    height: 95%;
    width: 40%;
    resize: none;
    display: none;
}

html,
body {
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: #fff;
}
#canvas {
    background-color: #008eaa;
    width: 740px;
    height: 100%;
}
#canvas-container {
    text-align: right;
    height: 100%;
}
#canvas-container.slide-in {
    animation-name: in-animation;
    animation-duration: 4s;
}
#canvas-container.slide-out {
    animation-name: out-animation;
    animation-duration: 4s;
}
@keyframes in-animation {
    from {
        margin-left: 100%;
    }
    to {
        margin-left: 0;
    }
}
@keyframes out-animation {
    from {
        margin-left: 0;
    }
    to {
        margin-left: 100%;
    }
}
